import {
  Button,
  Empty,
  Form,
  Pagination,
  Space,
  Table,
  Tooltip,
  Select,
  Input,
} from "antd";
import { useEffect, useState } from "react";
import {
  getAllWorkspace,
  getSystemAppListApi,
  getProcessStatusCallbackListApi,
} from "../../api/ServerApi";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import viewUtils from "../../utils/viewUtils";
import NavHyperLink from "../../components/NavHyperLink";

const ProcessStatusCallback = (props) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [datum, setDatum] = useState({ data: [], total: 0 });
  const [workspaceOptions, setWorkspaceOptions] = useState<any>({});
  const [appOptions, setAppOptions] = useState<any>({});
  const [filter, setFilter] = useState({ page: 1, page_size: 10, workspace_id: null, app_id: null, run_instance_id: null });

  // Fetch all data on initial load
  useEffect(() => {
    // Fetch all workspaces
    getAllWorkspace().then((res) => {
      if (res.status) {
        const workspacesData = res.data || [];
        let ret = {};
        for(let i = 0, len = workspacesData.length || 0; i < len; i++) {
          let workspace = workspacesData[i];
          ret[workspace.id] = {
            value: workspace.id,
            label: `${workspace.name}(${workspace.owner[0]?.account})`
          }
        }
        setWorkspaceOptions(ret);
      }
    });

    // Fetch all apps
    getSystemAppListApi({ page: 1, page_size: 100, pagination: true }).then((res) => {
      if (res.status) {
        const appsData = res.data || [];
        let ret = {};
        for(let i = 0, len = appsData.length || 0; i < len; i++) {
          let app = appsData[i];
          ret[app.id] = {
            value: app.id,
            label: app.app_manifest?.display_name[i18n.resolvedLanguage],
          }
        }
        setAppOptions(ret);
      }
    });
  }, [i18n.resolvedLanguage]);

  useEffect(() => {
    getProcessStatusCallbackListApi({
      page: filter.page,
      page_size: filter.page_size,
      workspace_id: filter.workspace_id,
      app_id: filter.app_id,
      run_instance_id: filter.run_instance_id,
    }).then((res) => {
      if (res.status) {
        setDatum({ data: res.data || [], total: res?.pagination?.total || 0});
      } else {
        setDatum({ data: [], total: 0 });
      }
    });
  }, [filter]);

  const handlePageChange = (page: number, page_size: number) => {
    setFilter((data) => ({ ...data, page: page, page_size: page_size }));
  };

  const handleSearch = () => {
    setFilter((data) => ({ ...data, ...form.getFieldsValue() }));
  }

  const handleReset = () => {
    form.resetFields();
    handleChangeFilters();
  };

// details
// process_type


  const columns: ColumnsType<any> = [
    {
      title: t("system_manage.process_status_callback.workspace"),
      key: "app",
      width: 220,
      render: (r) => (workspaceOptions[r?.process?.planning_session?.workspace_id] || {})?.label
    },
    {
      title: t("system_manage.process_status_callback.app"),
      key: "app",
      width: 200,
      render: (r) => (appOptions[r?.process?.planning_session?.app_id] || {})?.label
    },
    {
      title: t("system_manage.process_status_callback.run_instance"),
      key: "run_instance_id",
      width: 200,
      render: (r) => <Tooltip title={r?.process?.run_instance_id}>
        {r?.process?.run_instance?.name}
      </Tooltip>
    },
    {
      title: t("system_manage.process_status_callback.created_at"),
      key: "created_at",
      width: 180,
      render: (r) => viewUtils.utcToLocal(r.created_at),
    },
    {
      title: t("system_manage.process_status_callback.process_type"),
      key: "process_type",
      width: 100,
      render: (r) => r?.process?.process_type,
    },
    {
      title: t("system_manage.process_status_callback.active"),
      key: "active",
      width: 100,
      render: (r) => !!r.active ? '✅' : '❌',
    },
    {
      title: t("system_manage.process_status_callback.is_finished"),
      key: "created_at",
      width: 100,
      render: (r) => !!r.is_finished ? '✅' : '❌',
    }
  ];

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <NavHyperLink
          routes={[
            {
              path: "#",
              breadcrumbName: t("system_manage.nav.process_status_callback"),
            },
          ]}
        />
      </div>
      <Form
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3,1fr)",
          gridAutoRows: "auto",
          gap: "1em",
        }}
        form={form}
        colon={false}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        layout="inline"
        initialValues={{
          workspace_id: null,
          app_id: null,
        }}>
        <Form.Item
          name="workspace_id"
          label={t("system_manage.instance_data.workspace")}
        >
          <Select options={[
            { value: null, label: t("common.all") },
            ...(Object.values(workspaceOptions))
          ]} />
        </Form.Item>
        <Form.Item
          name="app_id"
          label={t("system_manage.instance_data.app")}
        >
          <Select options={[
            { value: null, label: t("common.all") },
            ...(Object.values(appOptions))
          ]} />
        </Form.Item>
        <Form.Item
          name="run_instance_id"
          label={t("system_manage.process_status_callback.run_instance_id")}
        >
          <Input />
        </Form.Item>
        <Form.Item
          style={{
            gridColumn: "1 / -1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Space>
            <Button type="primary" htmlType="submit" onClick={handleSearch}>
              {t("common.query")}
            </Button>
            <Button htmlType="reset" onClick={handleReset}>
              {t("common.reset")}
            </Button>
          </Space>
        </Form.Item>
      </Form>
      {
        (datum?.data?.length || 0) > 0 ? <div className="table-responsive bg-white" style={{ marginTop: "1em" }}>
          <Table
            pagination={false}
            rowKey={(r) => r.id}
            columns={columns}
            dataSource={datum?.data || []}
            scroll={{ x: 1100, scrollToFirstRowOnChange: true }}
            expandable={{
              expandedRowRender: p => <Table
                tableLayout='fixed'
                pagination={false}
                rowKey={r => r.time_at}
                columns={[
                  {
                    title: t("system_manage.process_status_callback.url"),
                    key: "url",
                    render: (r) => r?.url
                  },
                  {
                    title: t("system_manage.process_status_callback.time_at"),
                    key: "time_at",
                    render: (r) => viewUtils.utcToLocal(r.time_at),
                  },
                  {
                    title: t("system_manage.process_status_callback.status"),
                    key: "status",
                    render: (r) => r?.status,
                  },
                ]}
                dataSource={p.details}
              />,
            }}
          />
          <Pagination
            showQuickJumper
            showTotal={(total) => t("common.total", { count: datum?.total || 0 })}
            current={filter.page}
            pageSize={filter.page_size}
            total={datum?.total || 0}
            onChange={handlePageChange}
            showSizeChanger
            pageSizeOptions={[10, 20, 50]}
            style={{ textAlign: "right", justifyContent: 'flex-end', margin: "1em" }}
          />
        </div> : <div className="bg-white" style={{ padding: "10%" }}>
          <Empty/>
        </div>
      }
    </>
  )
};

export default ProcessStatusCallback;