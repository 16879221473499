import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';
import {
    BarChartOutlined,
  DatabaseOutlined,
  FundProjectionScreenOutlined,
  ProjectOutlined,
  UserOutlined,
} from '@ant-design/icons';

const NavMenu = (props: { account_info: any }) => {
    const { t } = useTranslation();
    const location = useLocation();

    const MenuList = [
        {
            label: <NavLink to={`/system_manage`}>
                { t('system_manage.nav.dashboard') }
            </NavLink>,
            key: "dashboard",
            icon: <FundProjectionScreenOutlined />
        },
        {
            label: t('system_manage.nav.app'),
            key: "app",
            icon: <ProjectOutlined />,
            children: [
                {
                    label: <NavLink to={`/system_manage/app_list`}>
                        { t('system_manage.nav.app_list') }
                    </NavLink>,
                    key: "app-list",
                    selected: location.pathname.includes('app_list'),
                },
                {
                    label: props.account_info?.with_special_permission ? <NavLink to={`/system_manage/app_permission`}>
                        { t('system_manage.nav.app_permission') }
                    </NavLink>: null,
                    key: "app-permission",
                    selected: location.pathname.includes('app_permission'),
                }
            ].filter(child => child.label !== null)
        },
        {
            label: t('system_manage.nav.flow'),
            key: "flow",
            icon: <DatabaseOutlined />,
            children: [
                {
                    label: <NavLink to={`/system_manage/instance_data`}>
                        { t('system_manage.nav.instance_data') }
                    </NavLink>,
                    key: "flow-instance-data",
                    selected: location.pathname.includes('instance_data'),
                },
                {
                    label: <NavLink to={`/system_manage/process_status_callback`}>
                        { t('system_manage.nav.process_status_callback') }
                    </NavLink>,
                    key: "flow-process-status-callback",
                    selected: location.pathname.includes('process_status_callback'),
                },
                // {
                //     label: <NavLink to={`/system_manage`}>
                //         { t('system_manage.nav.sessions') }
                //     </NavLink>,
                //     key: "flow-session",
                // },
                // {
                //     label: <NavLink to={`/system_manage`}>
                //         { t('system_manage.nav.workspaces') }
                //     </NavLink>,
                //     key: "flow-workspace",
                // }
            ]
        },
        {
            label: t('system_manage.nav.user'),
            key: "user",
            icon: <UserOutlined />,
            children: [
                {
                    label: <NavLink to={`/system_manage/user_list`}>
                        { t('system_manage.nav.user_list') }
                    </NavLink>,
                    key: "user-list",
                    selected: location.pathname.includes('user_list'),
                },
                {
                    label: <NavLink to={`/system_manage/auth_manage`}>
                        { t('system_manage.nav.auth_manage') }
                    </NavLink>,
                    key: "auth-manage",
                    selected: location.pathname.includes('auth_manage'),
                },
            ]
        },
        {
            label: t('system_manage.nav.chart'),
            key: "chart",
            icon: <BarChartOutlined />,
            children: [
                {
                    label: <NavLink to={`/system_manage/chart_list`}>
                        { t('system_manage.nav.chart_list') }
                    </NavLink>,
                    key: "chart-list",
                    selected: location.pathname.includes('chart_list'),
                },
            ]
        },
    ]

    const [defaultActiveKey, defaultOpenKeys] = MenuList.reduce((ret, item) => {
        let active: any = item;
        if (!!item.children) {
            active = item.children.find(
                (subitem) => !!subitem.selected && subitem.label
            );
        }
        if (!!active && !!active.selected) {
            ret = [active.key, item.children ? [item.key] : []];
        }
        return ret;
    }, ["dashboard", []]);

    return (
        <Menu
            theme="light"
            mode="inline"
            className='system-sidebar-menu'
            items={MenuList}
            defaultOpenKeys={defaultOpenKeys}
            defaultSelectedKeys={defaultActiveKey}
        ></Menu>
    );
};

const mapStateToProps = (store) => ({
    account_info: store.account.account_info,
})

export default connect(mapStateToProps, {})(NavMenu);
